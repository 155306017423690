.nav {
    background: $white;

    .navbar-inner {
        padding: 0 calc(var(--bs-gutter-x, 1.25rem) * 1);
    }

    .search-input {

        // width: calc(8rem + 22vw);
        &.input-group {
            .input-group-text {
                padding-left: 0;
                border-left: 0;
            }

            .form-control {
                border-right: 0;

                &:focus {
                    border-color: $input-border-color;
                }
            }

            &:focus-within {

                .input-group-text,
                .form-control {
                    border-color: $input-focus-border-color;
                }
            }
        }
    }

    .navbar-brand {
        display: flex;
        align-items: center;

        .logo-title {
            margin-left: $spacer;
            color: $dark;
            margin-bottom: 0;
            z-index: 1;
        }
    }

    .navbar-toggler {
        .navbar-toggler-icon {
            background-image: none;

            .navbar-toggler-bar {
                display: block;
                position: relative;
                width: 22px;
                height: 1px;
                border-radius: 1px;
                background: $body-color;
                transition: all 400ms ease;
                margin: 0 auto;
            }

            .navbar-toggler-bar.bar2 {
                margin-top: 7px;
            }

            .navbar-toggler-bar.bar3 {
                margin-top: 7px;
            }
        }
    }

    .navbar-toggler[aria-expanded=true] {
        .navbar-toggler-bar.bar1 {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
            margin-top: 4px;
        }

        .navbar-toggler-bar.bar2 {
            opacity: 0;
        }

        .navbar-toggler-bar.bar3 {
            transform: rotate(-45deg);
            transform-origin: 10% 90%;
            margin-top: 3px;
        }
    }


}

//navbar color
.iq-navbar.navs-color {
    background: var(--#{$variable-prefix}primary);

    .navbar-nav {
        .nav-item {
            .nav-link {
                color: $white;
            }
        }
    }
}

.iq-navbar {
    &.nav-glass {
        background: linear-gradient(120deg, rgba($white, 0.5) -8%, rgba($white, 0.01) 120%);
        backdrop-filter: blur(10px);
        border-bottom: $border-width solid $white;

        .navbar-nav {
            @include top-nav-link;
        }
    }

    &.navbar-expand-lg {
        &.navs-transparent {
            background: transparent;
            @include top-nav-link;
        }
    }

    &.navs-transparent {
        background-color: rgba($white, 0);
    }

    .dropdown {
        .dropdown-menu[data-bs-popper] {
            top: 110%;
            animation: fade-in-bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }

        .dropdown-menu {
            &.sub-drop {
                min-width: 15rem;
                border-radius: $border-radius;
                animation: fade-in-bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

                li {
                    a {
                        color: $body-color;
                    }
                }

                .iq-sub-card {
                    cursor: pointer;
                    padding: 0 !important;
                    display: inline-block;
                    width: 100%;
                    color: $body-color;
                    transition: all 400ms ease;

                    &:hover {
                        background: var(--#{$variable-prefix}primary-tint-90);
                    }

                    &:not(:last-child) {
                        border-bottom: $border-width solid $border-color;
                    }

                    &:last-child {
                        border-bottom-left-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                }
            }
        }
    }
}

@keyframes fade-in-bottom {
    0% {
        transform: translateY(25px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@include media-breakpoint-down(xl) {
    .nav {
        .sidebar-toggle {
            display: block;
        }
    }

}

    .iq-navbar {
        .navbar-collapse {
            &:not(.offcanvas-collapse) {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                flex-grow: unset;
                background: $white;
                z-index: 1081;
                box-shadow: $box-shadow-sm;
                transition: all 400ms ease;
            }

            .navbar-nav.navbar-list {
                &:not(.offcanvas-collapse) {
                    flex-direction: row;
                    float: right;
                }
            }
        }

        .dropdown {
            .dropdown-menu[data-bs-popper] {
                top: 126%;
            }
        }
    }



@include media-breakpoint-down(lg) {
    .iq-navbar {
        .navbar-collapse {
            &:not(.offcanvas-collapse) {
                position: absolute;
                top: 75%;
            }
        }
    }
}

//UI kit Navbars
.header {
    background-size: cover;
    background-repeat: no-repeat;
    height: 18.75rem;

    .navbar-light {
        .navbar-brand {
            color: var(--#{$variable-prefix}primary);
        }

        .navbar-toggler {
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280,0,0,0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }
        }

        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $gray-700;

                    @include hover-focus-active {
                        color: var(--#{$variable-prefix}primary);
                    }
                }
            }
        }
    }
}

.iq-header-logo {
    &:hover {
        color: var(--#{$variable-prefix}primary) !important;
    }
}

.iq-profile-menu-responsive {
    &.offcanvas-end {
        flex-grow: 0 !important;
    }
}

@include media-breakpoint-up(lg) {
    .navbar-inner {
        .offcanvas-backdrop.show {
            opacity: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .iq-navbar {
        .dropdown {
            .dropdown-menu[data-bs-popper] {
                top: 100%;
            }
        }
    }
}



@media screen and (max-width: 991px) {
    .iq-logo-none {
        display: none !important;
    }
}