// Main

$body-bg: #070f26;
$body-color: #c7c7c7;
$card-bg: #080d1e;
$border-color: #999;
$dark: #60658a;

$theme-colors: map-merge($theme-colors,
  (
    "primary": #A5A8AB,
    "secondary": white,
  )
);

$alert-bg-scale: 60%;
$alert-border-scale: 0%;
$alert-color-scale: 80%;
