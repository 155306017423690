.modal-content {
    background: $card-bg;

    .modal-header {
        border: none;
    }
    .modal-footer {
        border: none;
    }
}

.btn-close {
    filter: $btn-close-white-filter;
}