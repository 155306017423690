&{
    color: $body-color;
    background-color: $body-bg !important;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $body-color;
}
.border {
    border-color: $border-color !important;
}
.border-bottom {
    border-color: $border-color !important;
}
.datepicker-picker {
    background-color: $card-bg;
    .datepicker-title {
        background-color: $card-bg;
    }
    .datepicker-controls {
        .button {
            background-color: $card-bg;
            color: $body-color;
        }
    }
}
.table {
    border-color: $border-color;
    color: $body-color;
}
.offcanvas {
    background-color: $card-bg;
    color: $body-color;
}
button {
    &.close-btn-res {
        color: $light;
    }
}
p {
    color: white;
}
i {
    color: $body-color;
}
i:hover:not(.active):not([aria-expanded="true"]) {
    color: var(--bs-primary);
}
.nav-items {
    a.nav-link {
        text-decoration: none;
        text-transform: capitalize;
        color: white !important;
        text-align: center;
        padding:0.8rem 0.625rem;
    }
}
span {
    color: white;
}
form {
    color: white;
}
.modal-backdrop {
    background-color: #414346;
}
.sidebar-base {
    color: #080d1e;
}
.form-select {
    color: white !important;
}