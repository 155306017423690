a {
  color: #c7c7c7;
}

@media (hover: hover) {
  a:hover {
    color: var(--#{$variable-prefix}primary-shade-10);
  }
}

option {
  font-family: inherit;
}

div.imageUpload {
  padding: 3px;
}

div.imageUpload:hover {
  cursor: pointer;
  color: var(--bs-primary-shade-10) !important;
}

.post-image {
  margin-left: -1rem;
  margin-right: -1rem;

  .image {
    max-height: 500px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.avatar-image {
  max-height: 200px;
  object-fit: contain;
}

.image-upload {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.comments-image {
  max-height: 200px;
  object-fit: contain;
}

.top-navbar {
  top: 0;
  z-index: 20;
  position: sticky;
}

/*@media (min-width: 992px) {
  .top-navbar {
    position: sticky;
  }
}

@media (max-width: 992px) {
  .sticky-container {
    top: 0 !important;
  }
}*/

.user-img {
  min-width: 35px;
}

.share-button {
  margin: 0.5rem;
}

.email-code {
  border-radius: 3px;
  box-shadow: none;
  height: 50px;
  margin-right: 10px;
  outline: currentcolor none;
  padding: 0;
  text-align: center;
  width: 40px;
  size: 40px 50px;
}

.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.close-navbar-toggler.collapsed {
  z-index: -1;
}

.g_id_signin > div > div:first-child {
  display: none;
}

.hover-hand {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.create-icon {
  font-size: 1.2rem;
}

.create-p {
  margin-top: -0.25rem;
  margin-left: .4rem;
}

.create-options {
  &:hover {
    color: var(--#{$variable-prefix}primary-shade-10) !important;
  }
}

.poll-bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0.5rem;
  background-image: linear-gradient(to right, #9205e8, #0d6efd);
  //background-color: #FBBF24;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  opacity: 0.75;
}

.poll-card-body {
  padding: 0.7rem !important
}

.poll-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 100%;
}

.poll-content {
  line-height: 1.75rem;
  font-size: 1rem;
}

.poll-button {
  position: absolute;
  right: 1.25rem;
  bottom: .2rem;
}

.fb-login-button > div:nth-child(1) {
  width: 247px !important;
}

.fb-login-button > span:nth-child(1) {
  width: 247px !important;
}

.fb-login-button > span:nth-child(1) > iframe:nth-child(1) {
  width: 247px !important;
}

.welcome {
  color: black !important;
}

.welcome-bold {
  font-weight: 600;
  color: black !important;
}

.spinner-margin {
  margin-top: 0.33rem !important;
  margin-bottom: 0.33rem !important;
}

.create-spinner {
  margin-top: 0.33rem !important;
  margin-bottom: 0.33rem !important;
  margin-right: 23.5px;
  margin-left: 24px;
}

.repost-spinner {
  margin-top: 0.33rem !important;
  margin-bottom: 0.33rem !important;
  margin-right: 28.75px;
  margin-left: 24px;
}

.suggestions-spinner {
  margin-right: 1rem;
  margin-left: 0.95rem;
}

.verify-spinner {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important;
}

.rounded-circle {
  clip-path: circle();
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.comment-send-spinner {
  position: absolute;
  left: auto;
  margin-top: 0.35rem;
  right: 1.5rem;
}

.spinner-margin-2 {
  margin-top: 0.5625rem !important;
  margin-bottom: 0.62rem !important;
}

.form-control:valid {
  border-color: #999 !important;
  background-image: none !important;
}

.form-select:valid {
  border-color: #999 !important;
  background-image: none !important;
}

.feather-icon:active {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

.no-data-message {
  font-size: 16px;
}

.reply {
  font-size: 13px;
  margin-left: 1.5rem;
}

.btn.btn-primary:active {
  color: var(--bs-white);
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.btn.btn-primary:focus {
  color: var(--bs-white);
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
}

.main-content {
  margin-bottom: 2.5rem;
}

.nav-bar-icon {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.tenor-container {
  max-width: 99%;
}

.tag-link {
  color: white !important;
}

@media (hover: hover) {
  .tag-link:hover {
    color: white !important;
  }
}

.react-tagsinput {
  background-color: #080d1e;
  border: 1px solid #999;
  border-radius: 0.313rem;
  overflow: hidden;
  padding: 5px;
}

.react-tagsinput--focused {
  border-color: var(--bs-primary-tint-40);
}

.react-tagsinput-tag {
  background-color: var(--bs-primary);
  border-radius: .8rem;
  color: #638421;
  display: inline-block;
  margin: 2px 5px 2px 0;
  padding: 3px 5px 3px 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  color: white !important;
  font-family: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
  background: transparent;
  border: 0;
  margin-bottom: 1px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 150px;
}

.react-joyride__spotlight {
  background-color: lightgray !important;
  opacity: .2 !important;
}

.spaces-search {
  position: absolute;
  left: auto;
  right: 1.5rem;
  font-size: 1.4rem;
  a {
    color: $body-color;
    &.link{
      transform: rotate(130deg)
    }
  }
}

.spaces-height{
  height: calc(100vh - 11rem);
}

.paperplane-submit{
  background: none;
  border: none;
  width: 0;
  padding: 0;
  font-size: 1.5rem;
  right: 2.5rem
}

.time-ago {
  font-size: 12px;
  margin-top: -5px;
}

.displayname-block {
  margin-top: -4px;
}

.card-repost {
  background-color: #070f26 !important;
}

.color-white {
  color: $white !important;
}

.color-secondary-blue {
  color: var(--bs-primary-shade-10);
}

.color-secondary-blue:hover {
  color: var(--bs-primary);
}

.LinesEllipsis--clamped {
  cursor: pointer;
}

.LinesEllipsis--clamped:hover .LinesEllipsis-ellipsis {
  color: var(--bs-primary);
}

.white-space-wrap {
  white-space: pre-wrap;
}

.sticky-container {
  position: sticky !important;
  top: 76px;
  margin: auto;
  z-index: 10;
}

.display-inline {
  display: inline;
}