$value: 5;
@while $value <= 130 {
  .avatar-#{$value} {
    object-fit: contain;
    width: $value + px;
    line-height: $value + px;
  }
  $value: $value + 5;
}
.avatar {
  height: 2.5rem !important;
}
