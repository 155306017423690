section {
	&.sign-in-page {
		color: var(--bs-body-color);
		h1 {
			color: var(--bs-body-color);
		}
		p {
			color: var(--bs-body-color);
		}
		.form-control {
			color: var(--bs-body-color);
			background-color: white;
			border-color: #ced4da;
		}
		.form-select {
			color: var(--bs-body-color);
			background-color: white;
			border-color: #ced4da;
		}
	}
}
