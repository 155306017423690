.chat-page {
  img {
    @if $enable-rounded ==true {
      @include radius-mixin($border-radius);
    }
  }
}

#chat-search {
  padding-left: 2.5rem;
}

.form-group.chat-search-data {
  > i {
    position: absolute;
    top: 0.5rem;
    left: 0.938rem;
    color: var(--#{$variable-prefix}primary);

  }
}

.chat-sidebar-name {
  width: 100%;

  span {
    color: $body-color;
    font-size: 0.75rem;
  }
}

.chat-msg-counter {
  height: 0.938rem;
  width: 0.938rem;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }

  line-height: 1rem;
  margin: 0 auto;
  font-size: 0.438rem;
}

.chat-meta {
  span {
    font-size: 0.5rem;
  }
}

span.avatar-status {
  position: absolute;
  bottom: -0.7rem;
  top: auto;
  left: auto;
  right: -.4rem;
}

.iq-chat-ui {
  li {
    a {
      text-decoration: none;
      display: block;
      padding: 0.7rem 0;
    }

    a.active {
      border-color: transparent;
      color: $white !important;
    }

    &:last-child {
      a {
        border: none;
      }
    }

    &:nth-child(2) {
      a {
        border: none;
      }
    }

    &:nth-child(6) {
      a {
        border: none;
      }
    }
  }
}

.nav-pills {
  li {
    a.active {
      color: var(--#{$variable-prefix}primary);
      background-color: rgba(var(--#{$variable-prefix}primary-rgb), .2);
    }
  }
}

.chat-sidebar-channel {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 64vh;
  margin-bottom: 0.938rem;

  .avatar {
    position: relative;
  }

  h5 {
    border-bottom: 1px solid $light;
    padding-bottom: 1rem;
  }
}

.chat-header-icons {
  .dropdown-toggle {
    &:empty {
      &::after {
        display: none;
      }
    }
  }

  > a {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    margin: 0 0.313rem 0 0;
    color: var(--#{$variable-prefix}primary);
    display: inline-block;
    text-align: center;
    line-height: 2.5rem;

    @if $enable-rounded ==true {
      @include radius-mixin($border-radius);
    }

    cursor: pointer;
  }

  > span {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 20px;
    margin: 0 0.313rem 0 0;
    color: var(--#{$variable-prefix}primary);
    display: inline-block;
    text-align: center;
    line-height: 2.5rem;

    @if $enable-rounded ==true {
      @include radius-mixin($border-radius);
    }

    cursor: pointer;

    .dropdown-item {
      line-height: 26px;
    }
  }
}

.h-90 {
  height: 90% !important;
}

.chat-content {
  position: relative;
  max-height: 90%;
  text-align: center;
  padding: 1.4rem 1.4rem 0 1.4rem;
  overflow-y: scroll;
  overflow-x: hidden;

  .chat-left {
    .chat-message {
      text-align: left;
      // float: left;
      margin: 0 .3rem 1.8rem 1.3rem;

      @if $enable-shadows ==true {
        @include shadow-mixin(0px 0px 20px 0px rgba(44, 101, 144, 0.1));
      }
    }
  }

  .chat-body {
    .chat-message {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.chat-user {
  text-align: center;

  span.chat-time {
    display: block;
    font-size: 12px;
    font-weight: 500;
  }
}

.chat-detail {
  display: flex;
  justify-content: flex-end;

  .chat-message {
    .chat-time {
      position: absolute;
      bottom: -20px;
      right: 0;
      color: $body-color;
      font-size: .8rem;
      white-space: nowrap;
    }
  }
}

.chat-message {
  position: relative;
  text-align: right;
  padding: .75rem 1rem;
  margin: 0 1.33rem 1.33rem .3rem;
  clear: both;
  word-break: break-word;
  color: $white;
  background: var(--#{$variable-prefix}primary);

  @if $enable-rounded ==true {
    @include radius-mixin(0.938rem);
  }

  @if $enable-shadows ==true {
    @include shadow-mixin(0 2px 4px 0 rgba(80, 181, 255));
  }

  > p {
    margin: 0;
  }
}

.chat-left {
  display: flex;
  justify-content: flex-start;

  .chat-message {
    &::after {
      right: auto;
      left: -0.438rem;
      border-width: 0.313rem 0.438rem 0.313rem 0;
      border-color: transparent $white transparent transparent;
    }
  }
}

.other-user {
  flex-direction: row-reverse;
  text-align: right;
}

.avatar {
  position: relative;
}

.chat-start {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 64vh;
  flex-direction: column;
}

span.iq-start-icon {
  font-size: 3.438rem;
  background: $white;
  width: 100px;
  text-align: center;

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius-pill);
  }
}

.chat-attagement {
  font-size: 1.25rem;

  a {
    color: $body-color;
  }
}

.chat-searchbar {
  position: relative;
}

.chat-user-detail-popup {
  position: absolute;
  top: 0;
  width: 360px;
  background: $white;
  right: auto;
  overflow: hidden;
  left: auto;
  z-index: 9;
  height: 100%;
  opacity: 0;
  display: none;
  transform: translateX(110%);
  -webkit-transform: translateX(110%);


  @if $enable-shadows ==true {
    @include shadow-mixin(0px 0px 20px 0px rgba(44, 101, 144, 0.1));
  }

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius);
  }

  visibility: hidden;
}

.chat-user-detail-popup.show {
  opacity: 1;
  display: block;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);


}

.chatuser-detail {
  .title {
    font-weight: 500;
  }

  padding: 0 2.188rem;
}

.user-profile {
  button.close-popup {
    display: block;
    font-size: 1.5rem;
  }
}

ul.user-status {
  list-style: none;
}

#user-detail-popup {
  position: absolute;
  top: 0;
  width: 310px;
  background: $white;
  right: auto;
  overflow: hidden;
  left: 0;
  z-index: 9;
  height: 100%;
  opacity: 0;
  transform: translateX(-90%);
  -webkit-transform: translateX(-90%);


  @if $enable-shadows ==true {
    @include shadow-mixin(0px 0px 20px 0px rgba(44, 101, 144, 0.1));
  }

  @if $enable-rounded ==true {
    @include radius-mixin($border-radius);
  }

  visibility: hidden;

  .user-profile {
    button.close-popup {
      text-align: right;
      width: 100%;
    }
  }
}

#user-detail-popup.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);


}

button.close-btn-res {
  display: none;
}

.chat-head {
  div.sidebar-toggle {
    display: none;
  }
}

.chat-start {
  span.iq-start-icon {
    text-align: center;
  }
}

.chat-header-icons {
  span.dropdown {
    text-align: center;
  }
}

@include media-breakpoint-down(lg) {
  .chat-data-left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    background: #080d1e;
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;


    overflow-y: scroll;
    height: 100%;
  }

  .chat-sidebar-channel {
    overflow: auto;
    height: auto;
    padding-left: 0 !important;
  }

  // .chat-search {
  //     padding-left: 0 !important;
  // }
  .chat-data-left.show {
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
  }

  button.close-btn-res {
    display: block;
    background: transparent;
    border: none;
    font-size: 1.25rem;
    font-weight: 600;
    position: absolute;
    top: 0.625rem;
    left: auto;
    right: 0;
  }

  .chat-head {
    header {
      padding-left: 0.938rem;
    }

    div.sidebar-toggle {
      display: block;
    }
  }

  .chat-head {
    .sidebar-toggle {
      background: rgba(var(--#{$variable-prefix}primary-rgb), .2);
      padding: 0.938rem 0.5rem;
      margin-right: 0.938rem;

      @if $enable-rounded ==true {
        @include radius-mixin($border-radius);
      }

      height: 2.5rem;
      width: 2.5rem;
      line-height: 0.625rem;
      text-align: center;
    }
  }

  #chat-user-detail-popup {
    overflow-y: scroll;
    padding-bottom: 1.25rem;
  }

  #user-detail-popup {
    overflow: scroll;
  }


}

@include media-breakpoint-down(sm) {
  .chat-head {
    header {
      overflow-x: scroll;
    }
  }
}