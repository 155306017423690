$icon-fill: 0 !default;
$icon-weight: 200 !default;
$icon-grade: 0 !default;
$iocn-optical-size: 24 !default;
$font-size-base: 1rem !default;

/******* Custom Font Size: iqCustomFont **********/
.theme-fs-md {
  font-size: 16px;
}
@media (max-width: 1601px) {
  .theme-fs-md {
      font-size: 14px;
  }
}
