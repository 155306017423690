.nav-link{
    color: white;
    &:hover, &:focus {
        color: var(--#{$variable-prefix}primary);
    }
}
.nav-tabs{
    .nav-link{
        &.active{
            color:var(--#{$variable-prefix}white);
            background-color: var(--#{$variable-prefix}primary);
            border-color: var(--#{$variable-prefix}primary);
            @if($nav-tabs-shadow){
                box-shadow: 0 0.125rem 0.25rem rgba(var(--#{$variable-prefix}primary-rgb), 0.3);
            }
        }
    }
    &.nav-slider{
        .nav-link{
            &.active{
                @if($nav-tabs-shadow){
                    box-shadow: 0 0.125rem 0.25rem rgba(var(--#{$variable-prefix}primary-rgb), 0);
                }
            }
        }
    }
}

// .nav-pills {
//     .nav-link{
//         &.active{
//             color: var(--#{$variable-prefix}white);
//             background-color: var(--#{$variable-prefix}primary);
//         }
//     }
// }
.nav-slider{
    position: relative;
    .nav-link{
        &.active {
            background-color: transparent;
        }
    }
    .nav-item {
        z-index: 3;
    }
    .nav-slider-thumb {
        z-index: 1 !important;
        width: 100%;
        color: var(--#{$variable-prefix}primary);
        background: var(--#{$variable-prefix}primary);
    }
}

.nav-items {
    li {
        list-style: none;
        &:last-child {
            a {
                margin: 0;
            }
        }
        a.nav-link {
            text-decoration: none;
            text-transform: capitalize;
            //color: $primary;
            text-align: center;
            padding:0.8rem 0.625rem;
        }
    }
}

.nav-pills.nav-items {
    li {
        a.active {
            color: $white;
            background: var(--bs-primary);
        }
    }
}